import React from 'react'

function EventTabsComponent(props) {
  const Icon = props.data.icon;
  return (
    <div
      className={`${props.index == props.value && 'activeTab'}`}
      onClick={() => props.changeTab(props.index)}
    >
      <Icon className="icon"></Icon>
      <div className="tabName">{props.data.title}</div>
    </div>
  )
}

export default EventTabsComponent
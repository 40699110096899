import React from "react";
import "./Countdown.css";

export default function Countdown() {
  (function () {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    //I'm adding this section so I don't have to keep updating this pen every year :-)
    //remove this if you don't need it
    let today = new Date(),
      dd = String(today.getDate()).padStart(2, "0"),
      mm = String(today.getMonth() + 1).padStart(2, "0"),
      yyyy = today.getFullYear(),
      nextYear = yyyy + 1,
      dayMonth = "05/02/",
      event = dayMonth + yyyy;

    today = mm + "/" + dd + "/" + yyyy;
    if (today > event) {
      event = dayMonth + nextYear;
    }

    event+=' 21:00:00'
    //end

    const countDown = new Date(event).getTime(),
      x = setInterval(function () {
        const now = new Date().getTime(),
          distance = countDown - now;

        document.getElementById("days").innerText = Math.floor(distance / day)
        document.getElementById("hours").innerText = Math.floor(
            (distance % day) / hour
          )
          document.getElementById("minutes").innerText = Math.floor(
            (distance % hour) / minute
          )
          document.getElementById("seconds").innerText = Math.floor(
            (distance % minute) / second
          );

        //do something later when date is reached
        if (distance < 0) {
          document.getElementById("headline").innerText = "Techweek has Started!";
          document.getElementById("countdown").style.display = "none";
          document.getElementById("content").style.display = "block";
          clearInterval(x);
        }
        //seconds
      }, 0);
  })();
  return (
    <div className="container2">
      <h1 id="headline">Countdown to Techweek!</h1>
      <br/>
      <div id="countdown">
        <ul>
          <li>
            <span id="days"></span>days
          </li>
          <li>
            <span id="hours"></span>Hours
          </li>
          <li>
            <span id="minutes"></span>Minutes
          </li>
          <li>
            <span id="seconds"></span>Seconds
          </li>
        </ul>
      </div>
    </div>
  );
}

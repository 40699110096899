import React from 'react'
import './Footer.css'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { ReactComponent as IeeeLogo } from '../NavBar/ieeeLogo.svg';
import {Link} from 'react-router-dom'

function Footer() {
  return (
    <div className="footerContainer">
      <div className='footer'>
        <div className="left">
          <div className="sunken">Sunken</div>
          <div className="links">
            <span>&copy; IEEE SBM</span>
            <span><Link to={'/'} className='link'>Home</Link></span>
            <span><Link to={'/events'} className='link'>Events</Link></span>
            <span><Link to={'/faq'} className='link'>FAQ</Link></span>
          </div>
        </div>
        <div className="right">
          <a href="https://www.facebook.com/ieeesbmanipal/" target="_blank">
            <FaFacebookF className='fbicon' />
          </a>
          <a href="https://www.instagram.com/ieeesbm/?hl=en" target="_blank">
            <FaInstagram className='instaicon' />
          </a>
          <a href="https://twitter.com/ieeeorg?lang=en" target="_blank">
            <FaTwitter className='twittericon' />
          </a>
          <IeeeLogo className='logo' />
        </div>
      </div >
    </div >
  )
}

export default Footer
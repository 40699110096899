import React from "react";
import Contact from "../Contact/Contact";
import FAQ_Home from "../FAQ_Home/FAQ_Home";
import About from '../About/About'
import Footer from "../Footer/Footer";
import HomeEvents from "../HomeEvents/HomeEvents";
import Countdown from "../Landing/countdown";

import LandingTop from "../Landing/LandingTop";
import NavBarMob from "../NavBar/NavBarMob";
export default function Home() {
  return (
    <>
      <NavBarMob />
      <LandingTop />
    <Countdown/>
<About/>
      <HomeEvents />
      <FAQ_Home />
      <Contact />
      <Footer />
    </>
  );
}

import {DiCodeBadge} from 'react-icons/di';
import {FaNetworkWired} from 'react-icons/fa';
import {MdDesignServices} from 'react-icons/md';
import {BsPeopleFill} from 'react-icons/bs';
import {BiShieldQuarter} from 'react-icons/bi';

export const EventsData = [
  {
    id: 0,
    icon: DiCodeBadge,
    title: 'Map Your Way',
    subTitle: '2nd May',
    desc: `IEEE SBM presents to you a 2 hour fast-paced coding competition, Map Your Way. Are you ready to dive deep into the world of Atlantis and unravel its many secrets?`
  },
  {
    id: 1,
    icon: FaNetworkWired,
    title: 'IOTa',
    subTitle: '4th and 5th May',
    desc: `Knowledge and application of IOT will lead us into a
    growing and robust phase of the current tech world.
    We aim to provide this knowledge to people through an
    exciting IOT workshop, followed by a group task in which
    people will be able to implement what they have learned
    on hardware provided by us! The event will be capped off
    with an exciting competition with the chance to win
    exciting prizes. So get ready to use your skills to find the
    lost city with your IOT device.`
  },
  {
    id: 2,
    icon: MdDesignServices,
    title: 'Design Hack',
    subTitle: '6th May',
    desc:`A UI/UX hackathon revolving around colossal mysteries, a
    perfect platform for you to showcase your creativity and
    design thinking.
    Experience the serene wonders of the world underneath
    the waves with a challenge that provides you with an
    opportunity to take a peek into the world of design and
    the freedom to experiment with your crazy ideas.
    `
  },
  {
    id: 3,
    icon: BsPeopleFill,
    title: 'Informals Event',
    subTitle: '3rd May',
    desc: 'An exciting treasure hunt in which each team races against the clock to discover the iconic locations hidden throughout the campus.'
  },
  {
    id: 4,
    icon: BiShieldQuarter,
    title: 'Hack4Cause 3.0',
    subTitle: '7th and 8th May',
    desc: `Advancement in technology has greatly helped in
    preventing and controlling disasters.
    IEEE SBM brings to you a 36 hour hackathon themed
    around 'Effective use of Digitization in Disaster
    Mananagement'. It's the best opportunity to use your skills
    and come up with an innovative solution to the problem
    statements.`
  },
]


import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import './NavBarMob.css'
import Slider from './Slider'
import { ReactComponent as IeeeLogo } from './ieeeLogo.svg';
function NavBarMob() {
  const slideIn = () => {
    document.querySelector('.slider').style.left = '0px';
  }
  return (
    <>
      <div className="navbar">
        <IeeeLogo className='navImg' />
        <div className='navHome'><Link to={'/'} className='link'>Home</Link></div>
        <div className='navEvents'><Link to={'/events'} className='link'>Events</Link></div>
        <div className='navFAQ'><Link to={'/faq'} className='link'>FAQ</Link></div>
        <a href='https://unstop.com/p/hack4cause-30-ieee-manipal-294402' target="_blank">
        <div className='navRegister'>Register</div>

        </a>
      </div>
      <div className='navMob' >
        <div className="head">
          <GiHamburgerMenu className='hamburger' onClick={slideIn} />
          <div className="title">Sunken</div>
        </div>
        <Slider />
      </div>
    </>
  )
}

export default NavBarMob

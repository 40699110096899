import { useEffect, useState } from "react";
import { EventsData } from "./EventsData";
import './Events.css';
import EventDataComponent from "./EventDataComponent";
import EventTabsComponent from "./EventTabsComponent";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';
import NavBarMob from "../NavBar/NavBarMob";
import Footer from "../Footer/Footer";

function Events() {
  const [value, setValue] = useState(0);

  const changeTab = (index) => {
    setValue(index);
  }

  const handleArrowClick = (x) => {
    if (x) {
      setValue((value + 1) > 4 ? 0 : (value + 1));
    }
    else {
      setValue((value - 1) < 0 ? 4 : (value - 1));
    }
  }

  useEffect(() => {
    var elems = document.querySelectorAll('span');
    var index = 0, length = elems.length;
    for (; index < length; index++) {
      elems[index].style.background = '#ffffff';
      elems[index].style.height = '1px';
    }
    const target = '.color-' + value;
    document.querySelector(target).style.background = '#3734A9';
    document.querySelector(target).style.height = '5px';

  }, [value]);

  return (
    <>
      <NavBarMob />
      <div className="eventsBackground">
        <div className="events">
          <div className="heading">
            Events
          </div>
          <div className="tabsContainer">

            <div className="tabs">
              {
                EventsData.map((data, index) => {
                  return (
                    <EventTabsComponent index={index} value={value} data={data} changeTab={changeTab} />
                  )
                })
              }
            </div>

            <div className="data">
              {
                EventsData.map((data, index) => {
                  return (
                    <EventDataComponent index={index} value={value} data={data} />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>


      <div className="eventsMobileBackground">
        <div className="eventsMobile">
          <div className="heading">
            Events
          </div>
          <div className="data">
            <BsFillArrowLeftCircleFill
              className="leftarrow"
              onClick={() => handleArrowClick(0)}
            />
            <BsFillArrowRightCircleFill
              className="rightarrow"
              onClick={() => handleArrowClick(1)}
            />
            {
              EventsData.map((data, index) => {
                return (
                  <EventDataComponent index={index} value={value} data={data} />
                )
              })
            }
          </div>
          <div class="line">
            <span class="color-0"></span>
            <span class="color-1"></span>
            <span class="color-2"></span>
            <span class="color-3"></span>
            <span class="color-4"></span>
          </div>
          <div className="tabs">
            {
              EventsData.map((data, index) => {
                return (
                  <EventTabsComponent index={index} value={value} data={data} changeTab={changeTab} />
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;

import React from "react";
import "./FAQ.css";
import bgim1 from '../../assets/Bubbles.png'
import bgim2 from '../../assets/rightbg.png'
import NavBarMob from "../NavBar/NavBarMob";
import Footer from "../Footer/Footer";

export default function FAQ() {
  return (
    <div className="rules-container">
      <NavBarMob/>
    <img src={bgim1} className="bubble-bg"/>
    <img src={bgim2} className="right-bg"/>

      <div className="rules-h">FAQs</div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" id="accordion-item">
          <div class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              1) What is Atlantis?
            </button>
          </div>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Atlantis is the annual techweek conducted by IEEE SBM, indulging you in a week full of
        fun events spanning across domains of CS, Electronics, Graphics, Informals and a flagship
        Hackathon.
                    </div>
          </div>
        </div>

        <div class="accordion-item" id="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2) When is Atlantis scheduled?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Atlantis is scheduled to be conducted on 2nd - 7th May 2022            </div>
          </div>
        </div>

        <div class="accordion-item" id="accordion-item">
          <div class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
Is outstation participation allowed in events conducted under Atlantis?            </button>
          </div>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Outstation participation is allowed in all of the events except the informal event.
            </div>
          </div>
        </div>

        <div class="accordion-item" id="accordion-item">
          <div class="accordion-header" id="headingFour">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              4) Is there prize money for the winners of the events?
            </button>
          </div>
          <div
            id="collapseFour"
            class="accordion-collapse collapse show"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Yes, there is prize money for all of the events. The exact prize amount is mentioned under
the events themselves.            </div>
          </div>
        </div>

        <div class="accordion-item" id="accordion-item">
          <div class="accordion-header" id="headingFive">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              5) Will the equipment be provided to us for the electronics domain event or not?
            </button>
          </div>
          <div
            id="collapseFive"
            class="accordion-collapse collapse show"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Yes, you will be provided with a kit for the electronics event. You don't have to buy anything yourself.
            </div>
          </div>
        </div>

        <div class="accordion-item" id="accordion-item">
          <div class="accordion-header" id="headingSix">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              6) Is there any restriction on using a specific software for the graphics event?
            </button>
          </div>
          <div
            id="collapseSix"
            class="accordion-collapse collapse show"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Yes, you are only allowed to use Figma but you can design elements on Adobe Illustrator.
            </div>
          </div>
        </div>

        <div class="accordion-item" id="accordion-item">
          <div class="accordion-header" id="headingSeven">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              id="acc"
              data-bs-target="#collapseSeven"
              aria-expanded="true"
              aria-controls="collapseSeven"
            >
             7) Will certificates be provided to us?
            </button>
          </div>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse show"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
            Yes, certificates will be provided for the winners as well as for the participants.            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
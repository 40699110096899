import React from 'react'
import './NavBarMob.css'
import {Link} from 'react-router-dom'
import {ImCross} from 'react-icons/im'

function Slider() {
  const slideOut = () =>{
    document.querySelector('.slider').style.left = '-44.33vw';
  }
  return (
    <div className='slider'>
      <ImCross className='cross' onClick={slideOut}/>
      <div className='home nav'><Link to={'/'} className='link'>Home</Link></div>
      <div className='eventsHeading nav'><Link to={'/events'} className='link'>Events</Link></div>
      <div className='faq nav'><Link to={'/faq'} className='link'>FAQ</Link></div>
      <a href='https://unstop.com/p/hack4cause-30-ieee-manipal-294402' target="_blank">
      <div className='register'>Register</div>
      </a>
    </div>
  )
}

export default Slider

import React, { useState, useRef, useEffect } from "react";
import HomeEvents from "./components/HomeEvents/HomeEvents";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import FAQ from "./components/FAQ/FAQ";
import Events from "./components/Events/Events"

const App = () => {
  return (
    <>
      <Router>

        <Routes>
          <Route exact path="/" element={<Home/>}/>
           
          <Route exact path="/faq" element={<FAQ/>}/>

          <Route exact path="/events" element={<Events/>}/>
      
        </Routes>
    </Router>
     
    </>
  );
};

export default App;

import React from 'react'
import './About.css'
export default function About() {
  return (
    <div className='about_cont'>
                <div className='about-h'>
ABOUT
        </div>
        <div class="about">
        Atlantis is the flagship Techweek event by IEEE SBM. The
theme Atlantis deals with the mythological story of the
Lost City of Atlantis, under the sea.
It is one of the most significant technical events in Manipal,
comprising of 5 exciting events across the domains of
Electronics, Graphics, Computer Science, Informals and
Hackathon
        </div>
    </div>
  )
}

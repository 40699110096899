import React, { useState, useEffect } from "react";
import change from "../../assets/change.png";
import MapWay from "../../assets/MapWay.png";
import Hack4Cause from "../../assets/Hack4Cause.png";
import IOTa from "../../assets/IOTa.png";
import ScoutAbout from "../../assets/ScoutAbout.png";
import DesignHack from "../../assets/design_hack.png";
import Statue2 from "../../assets/Statue2.png";
import Statue from "../../assets/Statue.png";
import p11 from "../../assets/p1-1.png";
import p12 from "../../assets/p1-2.png";
import p21 from "../../assets/p2-1.png";
import p22 from "../../assets/p2-2.png";
import p31 from "../../assets/p3-1.png";
import p32 from "../../assets/p3-2.png";
import p41 from "../../assets/p4-1.png";
import p42 from "../../assets/p4-2.png";
import p51 from "../../assets/p5-1.png";
import p52 from "../../assets/p5-2.png";
import "./HomeEvents.css";

const HomeEvents = () => {
  var viewPortWidth = window.innerWidth;
  var viewPortHeight = window.innerHeight;
  console.log(viewPortHeight);
  console.log(viewPortWidth);
  return (
    <div className=".visible-xs-block hidden-xs eventscontainer">

   <img src={change} style={{height: "420vh", width : "auto", marginTop: "5%", marginLeft: "8%", alignSelf: 'center'}}/>

      
     {/* <div className="row">
       <div className="col-md-6 no-gutters">
         <div className="leftside">
        <img src={MapWay}  />
         </div>
       </div>
       <div className="col-md-6 no-gutters">
         <div className="text-timeline-align-rightside rightside">
         <div className="mapwayheading">
        <span className="head">Map Your Way</span>
        <span className="text">

         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation
          </span>
          </div>
         </div>
       </div>
     </div>

     <div className="row">
       <div className="col-md-6 no-gutters">
         <div className="text-timeline-align-leftside leftside">

         <div className="mapwayheading">
        <span className="head">IOTa</span>
        <span className="text">

         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation
          </span>
          </div>
         </div>
       </div>
       <div className="col-md-6 no-gutters">
         <div className="rightside">

         <img src={IOTa} />
         </div>
       </div>
     </div>

     <div className="row">
       <div className="col-md-6 no-gutters">
         <div className="leftside">
         <img src={DesignHack} style={{marginTop : "20%", marginLeft: "5%"}} />
         </div>
       </div>
       <div className="col-md-6 no-gutters">
         <div className="text-timeline-align-rightside rightside">
         <div className="mapwayheading">
        <span className="head">Design Hack</span>
        <span className="text">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation
          </span>
          </div>
         </div>
       </div>
     </div>

     <div className="row">
       <div className="col-md-6 no-gutters">
         <div className="text-timeline-align-leftside leftside">

         <div className="mapwayheading">
        <span className="head">Scout About</span>
        <span className="text">

         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation
          </span>
          </div>
         </div>
       </div>
       <div className="col-md-6 no-gutters">
         <div className="rightside">

         <img src={ScoutAbout}  />

         </div>
       </div>
     </div>

     <div className="row">
       <div className="col-md-6 no-gutters">
         <div className="leftside">
      
         <img src={Hack4Cause}  />

         </div>
       </div>
       <div className="col-md-6 no-gutters">
         <div className="text-timeline-align-rightside rightside">
         <div className="mapwayheading">
        <span className="head">Hack4Cause 3.0</span>
        <span className="text">

         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation
          </span>
          </div>
         </div>
       </div>
     </div>


      <img className="statue" src={Statue} />
      <img className="statue2" src={Statue2} /> 
       */}
    </div>
  );
};

export default HomeEvents;

import React from 'react'
import './landingTop.css'
import {RiCalendar2Line} from 'react-icons/ri'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {AiOutlineCalendar} from 'react-icons/ai'
import {ReactComponent as Submarine} from './submarine.svg';
import {ReactComponent as Blocks} from './blocks.svg';
import { Link } from 'react-router-dom'

function LandingTop() {
  return (
    <div className='landingBg'>
      <div className='landing'>
        <div className='landingText'>
          <div className='landingTitle'>Atlantis</div>
          <div className='landingSub'>Exploring the Lost</div>
          <div className='landingCalendar'>
            <RiCalendar2Line className='landingIcon' />
            <div className='landingDate'>2 MAY - 7 MAY</div>
          </div>
          <div className='landingText2'>A WEEK FULL OF FUN COMPETETIONS AWAITS YOU!</div>
          <div className='landingButtons'>
            <div className='landingRegister'>
            <Link to={'/events'}> <div className='landRegButton'>Explore </div> </Link>
              <AiOutlineArrowRight className='landingArrow'/>
            </div>
            {/* <div className='landingAdd'>
              <AiOutlineCalendar className='landingbuttonCal'/>
              <div>Add to Calendar</div>
            </div> */}
          </div>
        </div>
        <div className='landingImg'>
          <Submarine className='landingSubmarine'/>
          <Blocks className='landingBlocks'/>
        </div>
      </div>
    </div>
  )
}

export default LandingTop
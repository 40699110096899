import React from 'react'

function EventDataComponent(props) {
  const Icon = props.data.icon;
  return (
    <div className={`tabsContent ${props.index == props.value && 'showTab'}`}>
      <Icon className="dataIcon"></Icon>
      <div className="title">{props.data.title}</div>
      <div className="subtitle">{props.data.subTitle}</div>
      <div className="desc">{props.data.desc}</div>
    </div>
  )
}

export default EventDataComponent
import React from 'react'
import { Link } from 'react-router-dom'
import './FAQ_Home.css';
import bg from '../../assets/faqhbg.png';
export default function FAQ_Home() {
  return (
    <div className='FAQ_cont'>
    <img src={bg} className="faqhbg"/>
        <div className='faq-home-h'>
FAQ
        </div>

        <div className='faq-card'>
        <div className='faq-card-q'>What is Atlantis?</div>
        <div className='faq-card-ans'>Atlantis is the annual techweek conducted by IEEE SBM, indulging you in a week full of
        fun events spanning across domains of CS, Electronics, Graphics, Informals and a flagship
        Hackathon.</div>
        </div>

        <div className='faq-card'>
        <div className='faq-card-q'>When is Atlantis scheduled?</div>
        <div className='faq-card-ans'>Atlantis is scheduled to be conducted on 2nd - 7th May 2022.</div>
        </div>
        <div className='faq-card'>
        <div className='faq-card-q'>Is outstation participation allowed in events conducted under Atlantis?</div>
        <div className='faq-card-ans'>Outstation participation is allowed in all of the events except the informal event.</div>
        </div>
        <div className='faq-card'>
        <div className='faq-card-q'>Is there prize money for the winners of the events?</div>
        <div className='faq-card-ans'>Yes, there is prize money for all of the events. The exact prize amount is mentioned under
the events themselves.</div>
        </div>

        <div>
        <Link to={'/faq'}>
        <button className='faq-rm'>Read More</button>
        </Link>
        </div>
        
    </div>
  )
}